.footer {
  background-position: top;
  position: relative;
  z-index: 1;
  padding-top: 150px;
  color: black;
  margin-top: -140px;
  background: #1a017e;
}

.footer__bg {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../../assets/footer.png) no-repeat center center / cover;
  z-index: -1;
  background-position: top;
}

.footer__inner {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.footer__title {
  text-align: center;
  font-size: 100px;
  padding-right: 100px;
}

.footer__row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 240px;
}

.footer__column {
  flex-direction: column;
  display: flex;
  gap: 15px;
}

.footer__column__title {
  font-size: 35px;
}

.footer__column__line {
  display: flex;
  align-items: flex-start;
  gap: 18px;
}

.footer__column__line__icon {
  position: relative;
  top: 3px;
  width: 20px;
  height: 20px;
}

.footer__lower {
  position: relative;
  top: 60px;
  width: 100%;
  border-top-right-radius: 60px;
  border-top-left-radius: 60px;
  background: black;
  font-weight: 500;
  color: white;
  font-size: 1.05rem;
  padding: 32px 0;
  margin-top: 50px;
}

.footer__lower__inner {
  display: flex;
  justify-content: space-between;
}

.footer__nav {
  display: flex;
  align-items: center;
  gap: 50px;
}

.footerLinkHoverUnderline {
  position: relative;
}

.footerLinkHoverUnderline::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 1.5px;
  width: 100%;
  background: white;
  transform: scaleX(0);
  transition: transform 0.4s;
  transform-origin: bottom right;
}

.footerLinkHoverUnderline:hover::after {
  transform-origin: bottom left;
  transform: scaleX(1);
}

@media screen and (max-width: 1420px) {
  .footer__title {
    font-size: 80px;
    line-height: 90px;
  }
}

@media screen and (max-width: 1120px) {
  .footer__bg {
    background: url(../../assets/footerMobileBg.png) no-repeat center center / cover;
    background-position: top;
  }
  .footer__title {
    font-size: 60px;
    line-height: 70px;
    padding-right: unset;
  }
  .footer__row {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 20px;
  }
  .footer__column:nth-child(2) {
    align-self: flex-end;
    text-align: right;
  }
  .footer__column:nth-child(2) .footer__column__line {
    justify-content: flex-end;
  }
  .footer__lower__inner {
    flex-direction: column;
  }
  .footer__nav {
    flex-direction: column;
    gap: 20px;
  }
  .footer__rights {
    text-align: center;
    margin-bottom: 20px;
  }
  .footer__bg {
    top: 25px;
  }
  .footer__lower {
    margin-top: 0;
  }
}

@media screen and (max-width: 600px) {
  .footer__title {
    font-size: 45px;
    line-height: 50px;
  }
  .footer__column__title {
    line-height: 45px;
  }
}
