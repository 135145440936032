.services {
  position: relative;
  margin-top: -62px;
  padding-top: 240px;
  background: black;
  color: white;

  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  z-index: 2;
}

.services__title__section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: white;
}

.services__heading {
  text-align: left;
  font-size: 100px;
  transform: translateX(-100px);
  opacity: 0;
  transition: 0.3s;
  line-height: 100px;
}

.services__row {
  margin-top: 100px;
}

.services__row__inner {
  display: flex;
  gap: 100px;
  justify-content: space-between;
}

.services__row__image__container {
  max-width: calc(50% - 50px);
  width: 100%;
  min-width: 300px;
  flex-shrink: 0;
}

.services__row__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: translateX(-100px);
  opacity: 0;
  transition: 0.3s;
  transition-delay: 0.1s;
}

.servicesRowImageAnim {
  transform: translateX(0);
  opacity: 1;
}

.services__row__info {
  width: calc(50%-50px);
}

.services__row__info__text {
  width: 60%;
  font-weight: 300;
  font-size: 1.1rem;
}

.services__row__blobs {
  width: 400px;
  height: 400px;
  position: relative;
  margin-top: 60px;
  margin-left: 30px;
}

.services__row__blob {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.services__row__blob__number {
  font-size: 60px;
  position: relative;
  z-index: 500;
  font-style: italic;
  font-family: var(--secondary-ff);
}

.services__row__blob__figure {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.services__row__blob__text {
  width: 120px;
  position: relative;
  z-index: 500;
}

.services__row__blob__figure path {
  box-shadow: 0 0 0 2px white;
}

.services__row__blob:nth-child(1) .services__row__blob__number {
  color: #3fb858;
  font-size: 75px !important;
}
.services__row__blob:nth-child(1) .services__row__blob__figure {
  width: 400px;
  height: 400px;
}

.services__row__blob:nth-child(1) {
  width: 250px;
  height: 250px;
  top: 0;
  left: 0;
  animation: blob 2s forwards infinite;
  transform-origin: 50% 50%;
}

.services__row__blob:nth-child(2) .services__row__blob__number {
  color: #9fddff;

  font-size: 75px !important;
}

.services__row__blob:nth-child(2) .services__row__blob__figure {
  width: 370px;
  height: 370px;
}

.services__row__blob:nth-child(2) {
  width: 250px;
  height: 250px;
  top: 100px;
  right: -80px;
}

.services__row__blob:nth-child(3) .services__row__blob__number {
  color: #ff6301;

  font-size: 75px !important;
}

.services__row__blob:nth-child(3) .services__row__blob__figure {
  width: 380px;
  height: 380px;
}

.services__row__blob:nth-child(3) {
  width: 250px;
  height: 250px;
  top: 250px;
  left: 10px;
}

.services__row__blob__additional__first {
  position: absolute;
  right: 60px;
  top: -20px;
  width: 60px;
  height: 60px;
  box-shadow: 0 0 100px 2px rgb(0, 135, 46);
  background-color: rgba(0, 135, 46, 0.2);
  border-radius: 50%;
}

.services__row__blob__additional__second {
  position: absolute;
  top: 260px;
  left: -70px;
  width: 50px;
  height: 50px;
  box-shadow: 0 0 100px 2px rgb(0, 163, 255);
  background-color: rgba(0, 163, 255, 0.1);
  border-radius: 50%;
}

.services__row__blob__additional__third {
  position: absolute;
  bottom: -60px;
  right: 10px;
  width: 90px;
  height: 90px;
  box-shadow: 0 0 250px 2px #ffa801;
  border-radius: 50%;
  background-color: rgba(255, 168, 1, 0.1);
}

.services__button__container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 70px;
  padding-bottom: 80px;
}

.services__button {
  font-size: 25px;
  font-weight: 500;
  padding: 35px 75px;
  border-radius: 110px;
  background: #78e37e;
  color: black;
  transition: 0.3s;
  transform: translateX(-210px);
  box-shadow: 0 0 200px 1px rgba(120, 227, 126, 0.5);
}

.services__button:hover {
  background: #43ff4c;
}

@media screen and (max-width: 1420px) {
  .services__heading {
    font-size: 80px;
    line-height: 90px;
  }
  .services__title__section {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1120px) {
  .services {
    padding-top: 150px;
  }
  .services__heading {
    font-size: 60px;
    line-height: 70px;
  }
  .services__row__inner {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .services__row__info__text {
    width: 100%;
  }
  .services__row {
    margin-top: 30px;
  }
  .services__row__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .services__button__container {
    padding-bottom: 50px;
  }
  .services__button {
    transform: none;
    font-size: 16px;
    padding: 23px 60px;
  }
  .services__row__blobs {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 600px) {
  .services__row__image__container {
    max-width: 100%;
    width: 100%;
  }
  .services__heading {
    font-size: 38px;
    line-height: 45px;
  }
  .services__heading br {
    display: none;
  }
  .services__row__info {
    width: 100%;
  }
  .services__row__info__text {
    font-size: 14px;
    text-align: left;
  }
  .services__row__blobs {
    margin-bottom: 10px;
  }
  .services__row__blob:nth-child(1) .services__row__blob__figure {
    width: 300px;
    height: 300px;
  }

  .services__row__blob:nth-child(1) {
    width: 150px;
    height: 150px;
    color: white;
    top: 0;
    left: 30px;
    animation: blob 2s forwards infinite;
    transform-origin: 50% 50%;
  }

  .services__row__blob:nth-child(2) .services__row__blob__figure {
    width: 270px;
    height: 270px;
  }

  .services__row__blob:nth-child(2) {
    width: 150px;
    height: 150px;
    color: white;
    top: 100px;
    right: 30px;
  }

  .services__row__blob:nth-child(3) .services__row__blob__figure {
    width: 280px;
    height: 280px;
  }

  .services__row__blob:nth-child(3) {
    width: 150px;
    height: 150px;
    color: white;
    top: 250px;
    left: 60px;
  }

  .services__row__blob__additional__first {
    position: absolute;
    right: 30px;
    top: -20px;
    width: 60px;
    height: 60px;
  }

  .services__row__blob__additional__second {
    position: absolute;
    top: 180px;
    left: 15px;
    width: 50px;
    height: 50px;
  }

  .services__row__blob__additional__third {
    position: absolute;
    bottom: -20px;
    right: 30px;
    width: 90px;
    height: 90px;
  }
}
