.container {
  max-width: 1770px;
  width: 100%;
  padding: 0 60px;
  margin: 0 auto;
}

@media screen and (max-width: 820px) {
  .container {
    padding: 0 20px;
  }
}
