.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 28px 0;
  color: black;
  background: white;
  z-index: 1000 !important;
  transition: 0.3s;
  transform: translateY(-110%);
  opacity: 1;
}

.visible {
  transform: translateY(0);
  opacity: 1;
}

.transparent {
  background: transparent;
}

.header__logo {
  cursor: pointer;
}

.header__logo svg {
  display: flex;
  justify-content: flex-start;
}

.header__inner {
  max-width: 1770px;
  width: 100%;
  padding: 0 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__nav {
  display: flex;

  align-items: center;
  gap: 50px;
}

.header__link {
  font-weight: 500;
  cursor: pointer;
}

.header__button {
  border-radius: 35px;
  padding: 12px 30px;
  font-weight: 500;
  box-shadow: 0 0 0 1.5px black;
  transition: 0.2s var(--cubic-transition);
}

.hoverUnderline {
  position: relative;
}

.hoverUnderline::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 1.5px;
  width: 100%;
  background: black;
  transform: scaleX(0);
  transition: transform 0.4s;
  transform-origin: bottom right;
}

.hoverUnderline:hover::after {
  transform-origin: bottom left;
  transform: scaleX(1);
}

.header__menu {
  width: 25px;
  height: 20px;
  position: relative;
  display: none;
}

.header__menu__line {
  position: absolute;
  width: 100%;
  height: 2.5px;
  background: white;
  border-radius: 3px;
  transition: 0.3s;
}

.header__menu__line:nth-child(1) {
  top: 0;
}

.header__menu__line:nth-child(2) {
  top: 9px;
}

.header__menu__line:nth-child(3) {
  top: 18px;
}

.menu {
  position: fixed;
  width: 200px;
  top: 85px;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: black;
  z-index: 1000;
  text-align: center;
  border-bottom-left-radius: 20px;
  padding: 20px;
  transform: translateX(110%);
  transition: 0.3s;
  backdrop-filter: blur(3px);
  background: rgba(255, 255, 255, 0.8);
}

.header__menu__container {
  display: none;
}

.menuVisible {
  transform: translateX(0);
}

.menuIconTrasform .header__menu__line:nth-child(1) {
  transform: translate(1px, 9px) rotate(45deg);
}
.menuIconTrasform .header__menu__line:nth-child(2) {
  opacity: 0;
}

.menuIconTrasform .header__menu__line:nth-child(3) {
  transform: translate(1px, -9px) rotate(-45deg);
}

@media screen and (max-width: 1020px) {
  .header__nav {
    display: none;
  }
  .header__menu {
    display: flex;
  }
  .header__button {
    display: none;
  }
  .header {
    padding: 20px 0;
    background: transparent;
  }
  .header__logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header__logo svg {
    width: 25px;
    height: 25px;
  }
  .header__logo svg path,
  .header__logo svg circle {
    fill: white !important;
  }

  .header__menu__container {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
  }
}

@media screen and (max-width: 820px) {
  .header__inner {
    padding: 0 20px;
  }
}
