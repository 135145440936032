.intro {
  display: flex;
  flex-direction: column;
  padding-top: 140px;
  background: black;
  color: white;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: -2px;
  z-index: -1;
  margin-top: -50px;
}

.intro__inner {
  display: flex;
  flex-direction: column;
}

.intro__title__section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.intro__heading {
  font-size: 100px;
  line-height: 110px;
  transform: translateX(100px);
  opacity: 0;
  transition: 0.3s;
  text-align: right;
}

.intro__subheading {
  font-size: 35px;
  position: relative;
  top: 30px;
  transition: 0.3s;
  transform: translateX(-50px);
  opacity: 0;
}

.intro__text__container {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.intro__text {
  text-align: left;
  width: 35%;
  transform: translateX(-60px);
  opacity: 0;
  transition: 0.3s;
  transition-delay: 0.1s;
}

.introTextAnim {
  transform: translateX(-190px);
  opacity: 1;
}

.intro__row {
  display: flex;
  align-items: flex-end;
  gap: 30px;
  margin-top: 40px;
}

.intro__row__item__inner {
  width: 100%;
  height: 100%;
  padding: 60px 45px;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 35px;
  transition: 0.3s;
}

.intro__row__item__inner:hover {
  border-radius: 28px;
}

.intro__row__item {
  transform: translateX(-50px);
  opacity: 0;
  transition: 0.3s;
  box-shadow: 0 0 0 2px white;
  border-radius: 40px;
}

.intro__row__item:nth-child(1) {
  transition-delay: 0.15s;
}

.intro__row__item:nth-child(2) {
  transition-delay: 0.2s;
}

.introRowItemAnim {
  transform: translateX(0);
  opacity: 1;
}

.intro__row__item__icon {
  width: 150px;
  height: 150px;
  display: flex;
  flex-shrink: 0;
  transition: 0.3s;
}

.intro__row__item:hover .intro__row__item__info__title {
  transform: translateX(5px);
}

.intro__row__item:hover .intro__row__item__info__text {
  transform: translateX(5px);
}

.intro__row__item:hover .intro__row__item__icon {
  transform: translateX(5px);
}

.intro__row__item__info {
  display: flex;
  flex-direction: column;
  color: white;
  gap: 10px;
}

.intro__row__item__info__title {
  font-size: 1.6rem;
  transition: 0.3s;
  font-style: italic;
  margin-bottom: 10px;
  line-height: 2rem;
  font-weight: 500;
  font-family: var(--secondary-ff);
}

.intro__row__item__info__text {
  font-size: 1.05rem;
  font-weight: 300;
  transition: 0.3s;
}

.intro__row__button__container {
  width: 160px;
  height: 280px;
  flex-shrink: 0;
}

.intro__row__button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  border-radius: 110px;
  background: #78e37e;
  color: black;
  font-size: 28px;
  font-weight: 500;
  transform: translateY(50%);
  opacity: 0;
  transition: 0.3s;
  box-shadow: 0 0 200px 1px rgba(120, 227, 126, 0.3);
}

.intro__row__button:hover {
  background: #43ff4c;
}

.introRowButtonAnim {
  transform: translateY(0);
  opacity: 1;
}

@media screen and (max-width: 1350px) {
  .intro__heading {
    font-size: 80px;
    line-height: 90px;
  }
  .intro__subheading {
    font-size: 25px;
  }
  .intro__row {
    display: grid;
    grid-template-rows: 300px 100px;
    grid-template-columns: repeat(2, 1fr);
  }
  .intro__row__button {
    writing-mode: horizontal-tb;
  }
  .intro__row__button__container {
    width: 250px;
    height: 100px;
    grid-column-start: 2;
    grid-column-end: 3;
    justify-self: flex-end;
  }
  .intro__text {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .intro__row {
    grid-template-rows: 1fr 1fr 100px;
    grid-template-columns: 1fr;
  }

  .intro__row__button__container {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
    font-size: 18px;
  }
}

@media screen and (max-width: 1000px) {
  .intro__title__section {
    position: relative;
    flex-direction: column;
  }

  .intro__subheading {
    top: 0;
    right: 0;
  }
}

@media screen and (max-width: 600px) {
  .intro {
    padding-top: 80px;
  }
  .intro__heading {
    margin-top: 20px;
    font-size: 38px;
    line-height: 45px;
  }
  .intro__subheading {
    font-size: 16px;
  }
  .intro__row {
    gap: 20px;
  }
  .intro__row__item__inner {
    padding: 30px 25px;
    gap: 22px;
    border-radius: 30px;
  }
  .intro__row__item__info__title {
    font-size: 14px;
    line-height: 20px;
  }
  .intro__row__item__info__text {
    font-size: 12px;
  }
  .intro__row__item__icon {
    width: 80px;
    height: 80px;
  }
  .intro__row__button {
    font-size: 16px;
    white-space: nowrap;
  }
  .intro__row__button__container {
    width: 160px;
    height: 90px;
  }
}
