.products {
  padding-top: 120px;
  position: relative;
  z-index: 3;
  bottom: -60px;
  background: black;
  color: black;
  margin-top: -60px;
}

.products__title__section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.products__heading {
  color: white;
  font-size: 80px;
  line-height: 90px;
  opacity: 0;
  transform: translateX(-100px);
  transition: 0.3s;
}

.products__subheading {
  color: white;
  font-size: 35px;
  text-align: right;
  position: relative;
  top: 30px;
  opacity: 0;
  transform: translateX(50px);
  transition: 0.3s;
}

.products__row {
  margin-top: 150px;
  width: 100%;
  min-height: 100vh;
  background: url(../../assets/productsBg.png) no-repeat center center / contain;
}

.products__row__inner {
  position: relative;
  top: -100px;
  display: flex;
  width: 100%;
  gap: 30px;
  padding: 0 40px;
  align-items: flex-end;
  overflow: hidden;
}

.products__column {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: left;
  gap: 25px;
  transform: translateY(100px);
  opacity: 0;
  transition: 0.5s;
}

.products__column:hover .products__column__image {
  transform: scale(1);
}

.productsColumnAnim {
  transform: translateY(0);
  opacity: 1;
}

.products__column:nth-child(1) {
  transition-delay: 0.1s;
}

.products__column:nth-child(2) {
  transition-delay: 0.15s;
}
.products__column:nth-child(3) {
  transition-delay: 0.2s;
}

.products__column__image__container {
  width: 415px;
  height: 350px;
}

.products__column:nth-child(2) .products__column__image__container {
  width: 415px;
  height: 400px;
}

.products__column__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: 0.3s;
  transform: scale(0.98);
}

.products__column__info__title {
  font-size: 26px;
  font-weight: 500;
}

.products__column__info__text {
  font-weight: 300;
}

.products__column__info {
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-height: 200px;
}

.products__button__container {
  width: 100%;
  display: flex;
  position: relative;
  top: -60px;
  justify-content: center;
}

.products__button {
  color: black;
  font-size: 25px;
  font-weight: 500;
  padding: 35px 75px;
  box-shadow: 0 0 0 3px black;
  border-radius: 110px;
}

@media screen and (max-width: 1420px) {
  .products__heading {
    font-size: 70px;
    line-height: 80px;
  }
  .products__row__inner {
    flex-direction: column;
    align-items: center;
  }
  .products__row {
    background: url(../../assets/mobileBg.png) no-repeat center center / cover;
    background-position: top;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .products__column__info {
    max-width: 500px;
    text-align: center;
    min-height: unset;
  }
  .products__row__inner {
    gap: 40px;
  }
  .products__column__info br {
    display: none;
  }
}

@media screen and (max-width: 1120px) {
  .products {
    padding-top: 0;
  }
  .products__heading {
    font-size: 60px;
    line-height: 70px;
  }
  .products__subheading {
    display: none;
  }
  .products__title__section {
    justify-content: flex-end;
  }
  .products__column__image__container {
    width: 315px;
    height: 250px;
  }
  .products__column:nth-child(2) .products__column__image__container {
    width: 315px;
    height: 300px;
  }
  .products__button__container {
    top: -50px;
  }
  .products__button {
    font-size: 16px;
    padding: 23px 60px;
  }
}

@media screen and (max-width: 600px) {
  .products__heading {
    font-size: 38px;
    line-height: 45px;
    margin-bottom: 20px;
  }
  .products__heading br {
    display: none;
  }
  .products__row {
    margin-top: 110px;
  }
}
