.areas {
  color: white;
  position: relative;
  top: -105px;
  padding-top: 190px;
  padding-bottom: 3px;
  background: #1a017e;
}

.areas__inner {
  display: flex;
  gap: 80px;
  padding: 80px 50px;
  border-radius: 40px;
  box-shadow: 0 0 0 3px white;
  position: relative;
  z-index: 4;
}

.areas__info {
  width: calc(40% - 40px);
  text-align: right;
  overflow: hidden;
  padding-right: 3px;
}

.areas__info__button__container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.areas__info__button {
  font-size: 25px;
  font-weight: 500;
  padding: 35px 75px;
  border-radius: 110px;
  box-shadow: 0 0 0 3px white;
  transition: 0.3s;
  color: white;
}

.areas__info__button:hover {
  color: black;
  background: #78e37e;
  box-shadow: 0 0 0 3px #78e37e;
}

.areas__info__title {
  font-size: 75px;
  line-height: 90px;
  display: flex;
  flex-direction: column;
}

.areas__info__title__line {
  transform: translateX(80%);
  opacity: 0;
  transition: 0.5s;
}

.areasInfoTitleLineAnim {
  transform: translateX(0);
  opacity: 1;
}

.areas__info__title__line:nth-child(1) {
  transition-delay: 0.15s;
}

.areas__info__title__line:nth-child(2) {
  transition-delay: 0.3s;
}

.areas__info__title__line:nth-child(3) {
  transition-delay: 0.45s;
}

.areas__info__title__line:nth-child(4) {
  transition-delay: 0.6s;
}

.areas__info__subtitle {
  font-size: 35px;
  transition: 0.3s;
}

.areas__grid {
  width: calc(60% - 40px);
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.areas__grid__item:hover {
  border-radius: 20px;
}

.areas__grid__item:hover .areas__grid__item__title {
  transform: translateX(3px);
}

.areas__grid__item:hover .areas__grid__item__text {
  transform: translateX(3px);
}

.areas__grid__item:hover .areas__grid__item__icon {
  transform: translateX(3px);
}

.areas__grid__item {
  width: 100%;
  border-radius: 40px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  box-shadow: 0 0 0 2px white;
}

.areas__grid__item__title {
  margin: 20px 0 10px;
  font-size: 1.1rem;
  font-weight: 500;
  transition: 0.3s;
}

.areas__grid__item__text {
  font-style: italic;
  font-weight: 300;
  transition: 0.3s;
}

.areas__grid__item__icon {
  transition: 0.3s;
}

.areas__info__text {
  margin: 20px 0 35px;
}

.areas__info__button__container.mobile {
  display: none;
}

@media screen and (max-width: 1420px) {
  .areas__info__title {
    font-size: 60px;
    line-height: 70px;
  }
}

@media screen and (max-width: 1120px) {
  .areas__info__title {
    font-size: 50px;
    line-height: 60px;
  }
  .areas__info__subtitle {
    font-size: 25px;
  }
  .areas__info__text {
    margin-left: auto;
    width: 80%;
  }
  .areas__inner {
    flex-direction: column;
    align-items: center;
    gap: 0;
    padding: 30px 20px;
  }
  .areas__info {
    width: 100%;
    padding-bottom: 3px;
  }
  .areas__grid {
    width: 100%;
  }

  .areas__row {
    margin-top: 30px;
  }
  .areas__info__button {
    font-size: 16px;
    padding: 23px 60px;
  }
  .areas__info .areas__info__button__container {
    display: none;
  }
  .areas__info__button__container.mobile {
    display: flex;
    margin-top: 30px;
  }
}

@media screen and (max-width: 600px) {
  .areas {
    padding-top: 140px;
  }
  .areas__info__title {
    font-size: 40px;
    line-height: 50px;
  }
  .areas__grid {
    gap: 15px;
  }
  .areas__grid__item {
    padding: 17px 12px;
    border-radius: 26px;
  }
  .areas__grid__item__icon {
    width: 30px;
    height: 30px;
  }
  .areas__grid__item__title {
    font-size: 14px;
  }
  .areas__grid__item__text {
    font-size: 12px;
  }
}
