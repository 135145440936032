.intro__banner {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 80px;
  background: url(../../assets/introbg.png) no-repeat top center / cover;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  color: black;
  padding-bottom: 80px;
  padding-top: 130px;
  position: relative;
  z-index: 2;
}

.intro__banner__column {
  max-width: 400px;
}

.intro__banner__title {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  gap: 30px;
  font-size: 110px;
  line-height: 110px;
}

.lower {
  margin-bottom: 50px;
}

.intro__banner__row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 60px;
}

.intro__banner__column.link:hover .intro__banner__link__icon {
  right: -5px;
}

.intro__banner__column.link:hover .buttonTextUnderline::after {
  width: 100%;
}

.intro__banner__link__icon__container {
  position: relative;
  right: 0;
  opacity: 0;
  width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 6px black;
  border-radius: 50%;
  transform: translateX(-40px);
  transition: 0.3s;
  transition-delay: 0.25s !important;
}

.intro__banner__link__icon__container:hover .intro__banner__link__icon {
  transform: rotate(135deg) scale(1.05);
}

.intro__banner__column.link:hover .intro__banner__link__title {
  top: 7px;
}

.intro__banner__link__icon {
  width: 30px;
  height: 30px;
  transition: 0.3s;
}

.intro__banner__link__title {
  position: relative;
  top: 0;
  opacity: 0;
  transition: 0.3s;
  transition-delay: 0.3s;
  transform: translateX(-40px);
}

.introBannerLinkAnim {
  opacity: 1 !important;
  transform: translateX(0) !important;
}

.intro__banner__column__text {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.intro__banner__column__text__block {
  overflow: hidden;
}

.intro__banner__column__text__block p {
  opacity: 0;
  transform: translateY(20px);
  transition: 0.3s;
}

.intro__banner__column__text__block:nth-child(1) p {
  transition-delay: 0.35s;
}

.intro__banner__column__text__block:nth-child(2) p {
  transition-delay: 0.4s;
}

.intro__banner__column__text__block:nth-child(3) p {
  transition-delay: 0.45s;
}

.introBannerTextBlockAnim {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.intro__banner__link__title {
  color: black;
  text-align: center;
  font-size: 22px;
  margin-top: 10px;
}

.intro__banner__row,
.intro__banner__column__row {
  display: flex;
  align-items: center;
}

.intro__banner__column__row {
  gap: 20px;
}

.intro__banner__column__row__item {
  transition: 0.3s;
}

.intro__banner__column__row__item:hover {
  border-radius: 20px;
  transform: translateX(5px);
}

.intro__banner__column__row__item:hover .intro__banner__column__row__item__number {
  transform: translateX(5px);
}

.intro__banner__column__row__item:hover .intro__banner__column__row__item__text {
  transform: translate(5px, -5px);
}

.intro__banner__column__row__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 4px 36px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  width: 230px;
  height: 200px;
  padding: 30px;
  gap: 5px;
  opacity: 0;
  transition: 0.3s;
  opacity: 0;
  transform: translateX(-30px);
}

.intro__banner__column__row__item:nth-child(1) {
  background: var(--lightpurple);
  transition-delay: 0.2s;
}

.intro__banner__column__row__item:nth-child(2) {
  background: var(--yellow);
  color: var(--darkblue);
  transition-delay: 0.25s;
}

.intro__banner__column__row__item:nth-child(3) {
  background: var(--red);
  transition-delay: 0.3s;
}

.introBannerColumnRowItemAnim {
  opacity: 1 !important;
  transform: translateX(0) !important;
}

.intro__banner__column__row__item__number {
  font-size: 60px;
  font-weight: 600;
  transition: 0.3s;
}

.intro__banner__column__row__item__text {
  font-size: 18px;
  transition: 0.3s;
}

.intro__banner__column__text {
  font-size: 0.9rem;
}

.introBannerWordAnim {
  opacity: 1 !important;
  transform: translateX(0) !important;
}

.intro__banner__word {
  opacity: 0;
  transform: translateY(60px);
  transition: 0.5s;
}
.intro__banner__title.upper {
  position: relative;
}

.intro__banner__title.upper .intro__banner__word:nth-child(1) {
  opacity: 0;
  transform: translateY(60px);
}

.intro__banner__title.upper .intro__banner__word:nth-child(3) {
  opacity: 0;
  transform: translateY(60px);
  transition-delay: 0.05s;
}

.intro__banner__title.lower .intro__banner__word:nth-child(1) {
  opacity: 0;
  transform: translateY(60px);
  transition-delay: 0.1s;
}

.intro__banner__title.lower .intro__banner__word:nth-child(2) {
  opacity: 0;
  transform: translateY(60px);
  transition-delay: 0.15s;
}

.intro__banner__title.lower .intro__banner__word:nth-child(3) {
  opacity: 0;
  transform: translateY(60px);
  transition-delay: 0.2s;
}

.buttonTextUnderline {
  position: relative;
}

.buttonTextUnderline::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 3px;
  width: 0;
  background: black;
  transition: 0.2s;
}

.intro__banner__column .intro__banner__column.link {
  display: none;
}

.nexgen {
  position: absolute;
  top: -30px;
}

@media screen and (max-width: 1420px) {
  .intro__banner__title {
    width: 100%;
    align-items: flex-end;
    font-size: 100px;
    line-height: 110px;
    gap: 18px;
  }

  .intro__banner {
    background: url(../../assets/introbg.png) no-repeat center;
  }
  .intro__banner__title.upper {
    position: relative;
  }
  .puzzle {
    position: absolute;
    top: 300px;
    width: 150px;
    height: 150px;
  }
  .puzzle__parent {
    width: 140px;
    height: 140px;
  }
  .puzzle__child {
    display: none;
  }
  .intro__banner__title.lower .intro__banner__word:nth-child(1) {
    align-self: center;
  }
  .intro__banner__title.lower .intro__banner__word:nth-child(2) {
    align-self: flex-start;
  }
  .intro__banner__row {
    position: relative;
  }

  .intro__banner__column.link {
    order: 3;
  }
}

@media screen and (max-width: 820px) {
  .intro__banner {
    min-height: auto;
    background: url(../../assets/introBgMobile.png) no-repeat center center / cover;
    padding: 250px 0 40px;
  }
  .nexgen {
    width: 70px;
    height: 18px;
    top: -20px;
  }
  .lower {
    margin-bottom: 20px;
  }
  .intro__banner__title {
    font-size: 55px;
    line-height: 70px;
    gap: 12px;
  }
  .intro__banner__row {
    justify-content: flex-end;
    text-align: left;
  }
  .intro__banner__column__row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .puzzle {
    position: absolute;
    top: 150px;
    width: 100px;
    height: 100px;
  }
  .puzzle__parent {
    width: 90px;
    height: 90px;
  }
  .intro__banner__column__row__item {
    width: auto;
    height: 150px;
  }
  .intro__banner__column__row__item__number {
    font-size: 42px;
  }
  .intro__banner__link__icon__container {
    width: 110px;
    height: 110px;
  }

  .intro__banner__row .intro__banner__column.link {
    display: none;
  }
  .intro__banner__column .intro__banner__column.link {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .intro__banner__row {
    justify-content: flex-start;
    text-align: left;
  }
}
